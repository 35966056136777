<template>
  <div class="home" id="home">
    <nav class="navbar navbar-expand-lg rounded-pill py-5" style="padding-top: 12px !important; padding-bottom: 12px !important">
      <div class="container-fluid">
        <!-- Tombol hamburger -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Logo -->
        <a class="navbar-brand" href="#">
          <img src="../assets/logo.png" style="height: 40px" />
        </a>

        <!-- Menu navbar yang bisa di-collapse -->
        <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link text-white" href="#home" style="font-size: 15px">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#about" style="font-size: 15px">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#service" style="font-size: 15px">Services</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#contact" style="font-size: 15px">Contact Us</a>
            </li>
          </ul>
          <a href="#business" class="text-decoration-none business-btn ms-lg-3 mt-3 mt-lg-0">Business inquiries</a>
        </div>
      </div>
    </nav>

    <div class="head w-100 px-5 pt-5">
      <br />
      <br />
      <br />
      <br />
      <br />
      <div class="row">
        <div class="col-8 d-flex justify-content-start align-items-center">
          <div>
            <h5 class="text-blue">BLUE OCEAN STUDIO</h5>
            <h3 class="text-white pushing">Pushing the boundaries <br />of gaming.</h3>
            <br />
            <h4 class="text-white">Shaping the future of gaming with immersive, <br />innovative experiences.</h4>
            <br />
            <br />
            <a href="#game" class="text-decoration-none business-btn">Explore Our Game</a>
          </div>
        </div>
        <div class="col-2 d-flex justify-content-end align-items-center position-relative pembungkusAseet">
          <img src="../assets/iconic.png" class="iconic" />
          <div class="th-hero-wrapp">
            <div class="hero11-shape style1"></div>
            <div class="hero11-shape style2"></div>
            <div class="hero11-shape style3"></div>
            <div class="hero11-shape style4"></div>
            <div class="hero11-shape style5"></div>
            <div class="hero11-shape style6"></div>
            <div class="hero11-shape style7"></div>
            <div class="hero11-shape style8"></div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <h4 class="text-blue text-center" id="game">OUR FEATURE GAMES</h4>
    <h4 class="text-white text-center game-ini">Introducing Our Featured Game</h4>
    <div class="box w-100 overflow-hidden mt-5 d-flex box-vid">
      <div class="sample">
        <video src="https://demo-genbox.my.id/storage/trailer.mp4" class="sample-doc" muted autoplay loop />
      </div>
      <div class="d-flex justify-content-center px-5 align-items-center penutup">
        <div class="pem text-center">
          <h4 class="text-white text-center mb-5 text-1" style="font-size: 25px; font-weight: bolder">Astralist</h4>
          <h6 Class="text mb-5">
            Astralist is a story-driven, anime-style action-adventure RPG where Astrid, a leader of high-tech nation in dire need of help to free her nation from powerful enemy that enslaves her people, guide her as she navigates through
            Aster, a world divided into four distinct nations, each with unique landscapes, cultures, and challenges.
          </h6>
          <h6 Class="text">
            working with<br />
            <b>Habib Abdullah</b>
          </h6>
        </div>
      </div>
    </div>

    <br />
    <br />
    <br />

    <div class="box w-100 mt-5 py-5 px-5" id="about">
      <br />
      <br />
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <img src="../assets/people.png" class="w-100" />
        </div>
        <div class="col-6 px-5 d-flex align-items-center">
          <div class="kotak-gambar">
            <h6 class="text-blue about">ABOUT BLUE OCEAN STUDIO</h6>
            <h5 class="text-white Gaming">We Craft Gaming Experiences Beyond the Imagination.</h5>
            <br />
            <h6 class="create" style="text-align: justify">
              We create groundbreaking gaming experiences that push the boundaries of storytelling, gameplay, and technology. Inspiring players to explore, challenge themselves, and forge unforgettable memories in our immersive worlds, we
              are committed to empowering both individual players and businesses through innovative solutions. Our immersive gamification experiences and educational game development programs foster creativity, problem-solving, and digital
              literacy.
            </h6>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
    <br id="service" />
    <br />
    <br />
    <h4 class="text-blue text-center mt-5 our">OUR SERVICES</h4>
    <h3 class="text-center for">
      Personalized Solutions for<br />
      Your Game's Journey
    </h3>
    <br />
    <br />
    <div class="row full">
      <div class="col-4 d-flex justify-content-start kotak">
        <div class="card-service inti" @mouseenter="serviceOne" @mouseleave="lServiceOne">
          <img src="../assets/services/d.png" width="100px" class="service-1 mb-5" />
          <img src="../assets/services/d-h.png" width="100px" class="service-1h mb-5" style="display: none" />
          <h5>Full Cycle <br />Game Development</h5>
          <br />
          <p style="font-size: 15px">
            We offer a comprehensive range of services to help bring your game to life. Our expertise spans the entire game development and publishing process, ensuring that your project receives the attention and support it deserves.
          </p>
          <a href="#" class="learn-more mt-2">Learn More →</a>
        </div>
      </div>
      <div class="col-4 d-flex justify-content-center kotak">
        <div class="card-service inti" @mouseenter="serviceTwo" @mouseleave="lServiceTwo">
          <img src="../assets/services/l.png" width="100px" class="service-2 mb-5" />
          <img src="../assets/services/l-h.png" width="100px" class="service-2h mb-5" style="display: none" />
          <!-- <h3>Game Based Learning<br><span style="color: black;">.</span></h3> -->
          <h5>Game Based Learning</h5>
          <br />
          <p style="font-size: 15px">Innovative Educational Games. Our gamified solutions enhance learning by transforming education into exciting adventures. Aligned with your curriculum, our games motivate and inspire students.</p>
          <br />
          <a href="#" class="learn-more mt-2">Learn More →</a>
        </div>
      </div>
      <div class="col-4 d-flex justify-content-end kotak">
        <div class="card-service inti" @mouseenter="serviceThree" @mouseleave="lServiceThree">
          <img src="../assets/services/a.png" width="100px" class="service-3 mb-5" />
          <img src="../assets/services/a-h.png" width="100px" class="service-3h mb-5" style="display: none" />
          <h5>Game Developer <br />Academy</h5>
          <br />
          <p style="font-size: 15px">
            we offer a comprehensive range of services to help bring your game to life. Our expertise spans the entire game development and publishing process, ensuring that your project receives the attention and support it deserves.
          </p>
          <a href="#" class="learn-more mt-2">Learn More →</a>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="group-tim">
      <h5 class="text-blue text-start mt-5 judul-1">THE BLUE OCEAN STUDIO FAMILY</h5>
      <h4 class="text-white text-start bawah">United by a Passion for Games</h4>
      <br />
      <swiper
        :modules="modules"
        :slidesPerView="4"
        :centeredSlides="true"
        :loop="true"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        :spaceBetween="30"
        :navigation="true"
        :breakpoints="{
          640: { slidesPerView: 2, spaceBetween: 20 },
          380: { slidesPerView: 1, spaceBetween: 10 },
        }"
        class="mySwiper"
      >
        <swiper-slide>
          <div class="w-100">
            <div class="boxtim overflow-hidden">
              <div class="tim-img overflow-hidden d-flex justify-content-center align-items-center position-relative">
                <div class="d-flex justify-content-center align-items-center position-absolute bg-edu">
                  <span class="p-2 text-center d-block fs-6" style="text-shadow: 1px 1px 4px black">Data Analyst at PT Envy Technologies Indonesia Tbk, Prasetiya Mulya University Master's degree, New Ventures Innovation</span>
                </div>
                <img src="../assets/tim/Arin.png" width="100%" alt="Tim" />
              </div>
              <br />
              <h5 class="text-center">Astri Nurfajrin</h5>
              <p class="text-gray text-center">Chief Executive Officer</p>
              <br />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="w-100">
            <div class="boxtim overflow-hidden">
              <div class="tim-img overflow-hidden d-flex justify-content-center align-items-center position-relative">
                <div class="d-flex justify-content-center align-items-center position-absolute bg-edu">
                  <span class="p-2 text-center d-block fs-6" style="text-shadow: 1px 1px 4px black">Bachelor of Computer Science at Jakarta Bhayangkara University </span>
                </div>
                <img src="../assets/tim/Fauzan.png" width="100%" alt="Tim" />
              </div>
              <br />
              <h5 class="text-center">Fauzan Rizkulloh</h5>
              <p class="text-gray text-center">Chief Operation Officer</p>
              <br />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="w-100">
            <div class="boxtim overflow-hidden">
              <div class="tim-img overflow-hidden d-flex justify-content-center align-items-center position-relative">
                <div class="d-flex justify-content-center align-items-center position-absolute bg-edu">
                  <span class="p-2 text-center d-block fs-6" style="text-shadow: 1px 1px 4px black">Industrial Design Telkom University, 4 years at Creative Industries.</span>
                </div>
                <img src="../assets/tim/Nathan.png" width="100%" alt="Tim" />
              </div>
              <br />
              <h5 class="text-center">Nathaniel Jaenaro</h5>
              <p class="text-gray text-center">Product Manager</p>
              <br />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="w-100">
            <div class="boxtim overflow-hidden">
              <div class="tim-img overflow-hidden d-flex justify-content-center align-items-center position-relative">
                <div class="d-flex justify-content-center align-items-center position-absolute bg-edu">
                  <span class="p-2 text-center d-block fs-6" style="text-shadow: 1px 1px 4px black">Bachelor of Business Administration and Management at BINUS University</span>
                </div>
                <img src="../assets/tim/Jamal.png" width="100%" alt="Tim" />
              </div>
              <br />
              <h5 class="text-center">Jamal Abdau</h5>
              <p class="text-gray text-center">Chief Marketing Officer</p>
              <br />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="w-100">
            <div class="boxtim overflow-hidden">
              <div class="tim-img overflow-hidden d-flex justify-content-center align-items-center position-relative">
                <div class="d-flex justify-content-center align-items-center position-absolute bg-edu">
                  <span class="p-2 text-center d-block fs-6" style="text-shadow: 1px 1px 4px black"
                    >A Computer Science graduate from Gunadarma University with a passion for game development, particularly in storytelling and game mechanics. Interested in puzzle games like Undertale and Arknights, as well as simpler
                    rhythm games with character-driven narratives.</span
                  >
                </div>
                <img src="../assets/tim/Safira.png" width="100%" alt="Tim" />
              </div>
              <br />
              <h5 class="text-center">Safira Safa F</h5>
              <p class="text-gray text-center">Game Director</p>
              <br />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="w-100">
            <div class="boxtim overflow-hidden">
              <div class="tim-img overflow-hidden d-flex justify-content-center align-items-center position-relative">
                <div class="d-flex justify-content-center align-items-center position-absolute bg-edu">
                  <span class="p-2 text-center d-block fs-6" style="text-shadow: 1px 1px 4px black">Pasundan University Master's degree, Human Resources Management and Services</span>
                </div>
                <img src="../assets/tim/Fila.png" width="100%" alt="Tim" />
              </div>
              <br />
              <h5 class="text-center">Fila Rachma</h5>
              <p class="text-gray text-center">Legal Consultant</p>
              <br />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="w-100">
            <div class="boxtim overflow-hidden">
              <div class="tim-img overflow-hidden d-flex justify-content-center align-items-center position-relative">
                <div class="d-flex justify-content-center align-items-center position-absolute bg-edu">
                  <span class="p-2 text-center d-block fs-6" style="text-shadow: 1px 1px 4px black">Game Developer with 4 Years experience in Unity</span>
                </div>
                <img src="../assets/tim/Xenod.png" width="100%" alt="Tim" />
              </div>
              <br />
              <h5 class="text-center">Xenod Hyang Adecya</h5>
              <p class="text-gray text-center">Game Developer</p>
              <br />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="w-100">
            <div class="boxtim overflow-hidden">
              <div class="tim-img overflow-hidden d-flex justify-content-center align-items-center position-relative">
                <div class="d-flex justify-content-center align-items-center position-absolute bg-edu">
                  <span class="p-2 text-center d-block fs-6" style="text-shadow: 1px 1px 4px black"
                    >Professional Illustrator with 8 years of experience collaborating with clients to conceptualize and create high-quality illustrations. A strong history of using modern design principles to produce engaging visual
                    solutions and enhance brand recognition.</span
                  >
                </div>
                <img src="../assets/tim/Dinda.png" width="100%" alt="Tim" />
              </div>
              <br />
              <h5 class="text-center">Dinda Novita Padmasari</h5>
              <p class="text-gray text-center">2D Artist</p>
              <br />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="w-100">
            <div class="boxtim overflow-hidden">
              <div class="tim-img overflow-hidden d-flex justify-content-center align-items-center position-relative">
                <div class="d-flex justify-content-center align-items-center position-absolute bg-edu">
                  <span class="p-2 text-center d-block fs-6" style="text-shadow: 1px 1px 4px black">3D art is my passion. I love bringing animations and games to life.</span>
                </div>
                <img src="../assets/tim/Rendi.png" width="100%" alt="Tim" />
              </div>
              <br />
              <h5 class="text-center">Muhammad Rendi Oktora</h5>
              <p class="text-gray text-center">3D Artist</p>
              <br />
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- Tombol Prev dan Next di luar swiper -->
      <div class="custom-prev"><!--<i class="fa-solid fa-arrow-left"></i>--></div>
      <div class="custom-next"><!--<i class="fa-solid fa-arrow-right"></i>--></div>
      <div class="ball-prev"></div>
      <div class="ball-next"></div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div class="box py-5 kotak-pen" id="business">
      <h4 class="text-blue text-center join">JOIN THE ADVENTURE</h4>
      <h3 class="text-white text-center ready">
        Ready to level up your gaming experience?<br />
        Don't wait, start today!
      </h3>
      <p class="text-gray text-center mt-3 patner" style="font-size: 15px">
        Partner with Blue Ocean Studio and transform your vision into a captivating and successful game. Contact us <br />for a free consultation and discuss how we can bring your dream to life.
      </p>
      <br />
      <br />
      <form class="px-5 container">
        <div class="row mb-3">
          <div class="col-6 position-relative">
            <input type="text" class="form-control" placeholder="Your Name" />
            <img src="../assets/email/Name.svg" class="form-icon" style="position: absolute; right: 30px; top: 30%" height="20px" />
          </div>
          <div class="col-6 position-relative">
            <input type="email" class="form-control" placeholder="Your Email" />
            <img src="../assets/email/Email.svg" class="form-icon" style="position: absolute; right: 30px; top: 32%" height="20px" />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-3 position-relative">
            <input type="text" class="form-control" placeholder="Select Subject" />
            <img src="../assets/email/Subject.svg" class="form-icon" style="position: absolute; right: 30px; top: 45%" height="8px" />
          </div>
          <div class="col-6 position-relative">
            <input type="text" class="form-control" placeholder="Phone Number" />
            <img src="../assets/email/Phone Number.svg" class="form-icon" style="position: absolute; right: 30px; top: 20%" height="25px" />
          </div>
        </div>
        <div class="mb-3 position-relative">
          <textarea class="form-control textarea" style="height: 120px" id="exampleFormControlTextarea1" placeholder="your message" rows="6"></textarea>
          <img src="../assets/email/Message.svg" class="form-icon" style="position: absolute; right: 12px; top: 10px" height="25px" />
        </div>
        <button class="send-message-btn">Send Message →</button>
      </form>
    </div>
    <br />
    <br />
    <br />
    <br />
    <hr class="mb-5" />
    <div class="row" id="contact">
      <div class="col-md-10">
        <h5>PT Oceanic Digital Craft Studio Address</h5>
        <img src="../assets/line1.svg" class="w-25" />
        <br />
        <br />
        <a href="https://wa.me/6285183132832" class="mb-1 fs-5 text-white d-block text-decoration-none no">+62 851 8313 2832</a><br />
        <a href="https://www.instagram.com/blueoceanstudio.id/"><img src="../assets/IG.svg" class="me-3" width="50px" /></a>
        <a href="https://www.linkedin.com/company/blue-ocean-studio-indonesia/"><img src="../assets/Linkedin.svg" width="50px" /></a>
      </div>
      <div class="col-md-2">
        <h5>Address</h5>
        <img src="../assets/line2.svg" class="w-50 garis-bawah" />
        <h5 style="font-size: 15px" class="text-gray text-reguler">Bandung, Jawa barat Indonesia, Bandung 40921</h5>
      </div>
    </div>
    <hr class="mt-5" />
    <div class="copyright">
      <h5 class="copy">Copyright © 2024 <span class="text-blue">Blue Ocean Studio.</span> All Rights Reserved.</h5>
      <div class="link">
        <ul class="d-flex p-0 m-0 test" style="list-style: none">
          <li class="p-0 m-0" style="font-size: 15px"><a class="text-white mx-5 tes text-decoration-none" href="#service">Services</a></li>
          <li class="p-0 m-0" style="font-size: 15px"><a class="text-white mx-5 tes text-decoration-none" href="#about">About</a></li>
          <li class="p-0 m-0" style="font-size: 15px"><a class="text-white mx-5 tes text-decoration-none" href="#home">Home</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default {
  name: "HomeView",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    // Menambahkan modules Swiper yang dibutuhkan
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  methods: {
    // Fungsi untuk mengganti visibility elemen tertentu
    toggleService(classToHide, classToShow) {
      const hideElement = document.querySelector(classToHide);
      const showElement = document.querySelector(classToShow);
      if (hideElement && showElement) {
        hideElement.style.display = "none";
        showElement.style.display = "block";
      }
    },
    restoreService(classToShow, classToHide) {
      const showElement = document.querySelector(classToShow);
      const hideElement = document.querySelector(classToHide);
      if (showElement && hideElement) {
        showElement.style.display = "block";
        hideElement.style.display = "none";
      }
    },
  },
  mounted() {
    // Fungsi untuk memeriksa posisi scroll
    const checkScrollPosition = () => {
      const dvh = window.innerHeight / 2; // Mendapatkan nilai 50vh
      const navbar = document.querySelector(".navbar");
      if (navbar) {
        if (window.scrollY > dvh) {
          navbar.classList.add("navscroll"); // Tambahkan class untuk efek scroll
        } else {
          navbar.classList.remove("navscroll"); // Hapus class efek scroll
        }
      }
    };

    // Tambahkan event listener untuk scroll
    window.addEventListener("scroll", checkScrollPosition);

    // Event handler untuk tombol navigasi custom Swiper
    const nextButton = document.querySelector(".custom-next");
    const prevButton = document.querySelector(".custom-prev");
    const ballNext = document.querySelector(".ball-next");
    const ballPrev = document.querySelector(".ball-prev");

    if (nextButton) {
      nextButton.addEventListener("click", () => {
        const swiperNext = document.querySelector(".swiper-button-next");
        swiperNext?.dispatchEvent(new Event("click"));
      });
    }

    if (prevButton) {
      prevButton.addEventListener("click", () => {
        const swiperPrev = document.querySelector(".swiper-button-prev");
        swiperPrev?.dispatchEvent(new Event("click"));
      });
    }

    if (ballNext) {
      ballNext.addEventListener("click", () => {
        const swiperPrev = document.querySelector(".swiper-button-prev");
        swiperPrev?.dispatchEvent(new Event("click"));
      });
    }

    if (ballPrev) {
      ballPrev.addEventListener("click", () => {
        const swiperNext = document.querySelector(".swiper-button-next");
        swiperNext?.dispatchEvent(new Event("click"));
      });
    }
  },
};
</script>

<style scoped>
body {
  overflow-x: hidden;
}
.pushing {
  font-size: 60px !important;
  font-weight: bolder;
}
.sample {
  width: 100%;
}
.Gaming {
  font-size: 40px !important;
}
.bawah {
  font-size: 30px !important;
  font-weight: bolder;
}
.custom-next,
.custom-prev {
  background-color: white;
  font-size: 11px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px !important;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.custom-next i,
.custom-prev i {
  font-size: 20px;
}

.custom-next::after,
.custom-prev::after {
  font-size: 16px !important;
}

.group-tim:hover .custom-next {
  opacity: 1;
}

.group-tim:hover .custom-prev {
  opacity: 1;
}

.group-tim:hover .ball-next {
  opacity: 1;
}

.group-tim:hover .ball-prev {
  opacity: 1;
}

.custom-next {
  top: 60%;
  right: -115px;
  color: white !important;
  opacity: 0;
  transition: 0.5s;
}

.custom-prev {
  top: 60%;
  left: -115px;
  color: white !important;
  opacity: 0;
  transition: 0.5s;
}

.ball-next {
  top: 41%;
  left: -305px;
  opacity: 0;
  transition: 0.5s;
}

.ball-prev {
  top: 41%;
  right: -305px;
  opacity: 0;
  transition: 0.5s;
}

.ball-next {
  width: 300px;
  height: 300px;
  background: radial-gradient(#0084ff 0%, black 80%, black 100%);
  position: absolute;
  z-index: 81;
}

.swiper-button-next {
  transform: translateX(80px) !important;
}
.boxtim {
  background: radial-gradient(#08181f00, #08181f);
  border-radius: 25px;
  border: 1px solid #707172;
}

.swiper-button-prev {
  transform: translateX(-80px) !important;
}

.group-tim {
  position: relative;
}
.box-vid {
  height: 530px;
}

@media (max-width: 576px) {
  .sample {
    width: 100% !important;
  }

  .box-vid {
    height: 950px !important;
  }
}

h3 {
  font-size: 30px !important;
  font-weight: 600 !important;
}

h2 {
  font-size: 48px !important;
  font-weight: bold !important;
}

p {
  font-size: 18px;
}

.form-control {
  height: 60px;
}

/* Default hidden state for the .bg-edu element */
.bg-edu {
  background-color: #09a9ff88;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  display: none !important; /* Hide by default */
  z-index: 2; /* Ensure it's above the image */
}

/* Show the .bg-edu div when the parent container is hovered */
.tim-img:hover .bg-edu {
  display: flex !important; /* Show the overlay when hovered */
}

/* Optional styling to make the text centered inside the div */
.bg-edu span {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

/* Styling for the image container */
.tim-img {
  border-radius: 25px;
  position: relative; /* Make it a positioning context for .bg-edu */
  z-index: 1;
}

.send-message-btn {
  background: linear-gradient(to right, #00f7ff, #0084ff); /* Gradient background */
  color: white; /* Text color */
  border: none;
  padding: 15px 40px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px; /* Rounded corners */
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Shadow effect */
  transition: all 0.3s ease; /* Smooth transition */
  width: 100%;
}

.send-message-btn:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  transform: translateY(-3px); /* Slight lift on hover */
}

.send-message-btn:focus {
  outline: none;
}

.tim-img {
  border-radius: 25px;
}

.text-blue {
  color: #89cff0;
}

.text-gray {
  color: #707172;
}

.home {
  padding: 30px 80px;
}

nav {
  border: 1px solid #707172;
  background-color: rgba(231, 241, 241, 0.045);
  position: fixed !important;
  top: 60px;
  left: 0;
  width: 100%;
  padding: 130px 100px !important;
  z-index: 99;
  backdrop-filter: blur(10px);
}

.navscroll {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 0 !important;
  background-color: #141d38 !important;
  border: unset !important;
}
@media (max-width: 380px) {
  .navscroll {
    width: 450px !important;
    border-radius: 0 !important;
    background-color: #141d38 !important;
    border: unset !important;
  }
  .custom-next {
    display: none;
  }
}

.business-btn {
  background: linear-gradient(to bottom, #58a8e8, #1d6b99);
  color: white;
  border: none;
  border-radius: 25px;
  padding: 6px 25px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.business-btn:hover {
  background: linear-gradient(to bottom, #69b4f0, #1d6b99);
}

.business-btn:focus {
  outline: none;
}

.head {
  background: radial-gradient(#08181f00, #08181f);
  height: 120vh;
  border-radius: 35px;
  margin-bottom: 50px;
}

.box {
  background: radial-gradient(#08181f00, #08181f);
  border-radius: 35px;
  border: 1px solid #707172;
}

.box2 {
  background: radial-gradient(#08181f00, #08181f);
  border-radius: 25px;
  border: 1px solid #707172;
  height: 550px;
}

/* editan saya */
.game-ini {
  font-size: 28px !important;
  font-weight: bold;
}
.penutup {
  width: 40%;
}
.judul {
  font-size: 30px;
  font-weight: bolder;
}

.bg-digi::after {
  content: "";
  right: 0;
  position: absolute;
  z-index: 1;
  background-image: url(../assets/bg-digi.png);
  background-size: 30%;
  background-position: center;
  background-repeat: no-repeat;
  width: 50%;
  height: 500px;

  /* Animasi untuk background image */
  animation: moveUp 5s infinite;
  /* Atur durasi animasi dan ulangi terus-menerus */
}
.Gaming {
  font-size: 20px;
  font-weight: bolder;
}

/* Keyframes untuk menggerakkan gambar */
@keyframes moveUp {
  0% {
    background-position: center bottom; /* Mulai dari bawah */
    opacity: 1;
  }
  50% {
    background-position: center top; /* Naik ke atas */
    opacity: 0; /* Menghilang saat mencapai atas */
  }
  51% {
    background-position: center bottom; /* Langsung muncul lagi di bawah */
    opacity: 0;
  }
  100% {
    background-position: center; /* Kembali ke tengah (bisa disesuaikan) */
    opacity: 1; /* Muncul lagi */
  }
}

.sample {
  position: relative;
  overflow: hidden;
  margin-top: -30px;
  margin-left: -50px;
}

.sample-doc {
  /* width: 200%; */
  height: 100%;
  /* position: absolute; */
  /* left: -300px; */
}

.card-service {
  padding: 60px;
  width: 450px;
  height: 530px;
  border-radius: 35px;
  transition: 0.2s;
}

.card-service:hover {
  background-color: #001b21;
  cursor: pointer;
}
.learn-more {
  color: #00bfff; /* Start color */
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  position: relative;
  padding-bottom: 5px;
  background: linear-gradient(to right, #00bfff, #89cff0); /* Gradient for text */
  -webkit-background-clip: text;
  color: transparent;
}

.learn-more:hover {
  background: linear-gradient(to right, #89cff0, #00bfff);
  -webkit-background-clip: text;
  color: transparent;
}

.learn-more::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #00bfff, #89cff0); /* Gradient for underline */
  transition: width 0.3s;
  position: absolute;
  left: 0;
  bottom: 0;
}

.learn-more:hover::after {
  width: 100%;
  background: linear-gradient(to right, #89cff0, #00bfff);
}
.ready {
  font-size: 25px !important;
  font-weight: bolder;
}
.copy {
  font-size: 15px;
}

/* Navbar */
@media (max-width: 380px) {
  nav {
    position: relative; /* Atur posisi relatif untuk induk */
    border: none;
    background: none;
    margin-top: -15px;
    margin-left: -80px;
    width: 400px !important;
  }

  nav img {
    position: absolute; /* Membuat gambar melayang */
    top: -20px; /* Naik ke atas 20px di luar navbar */
    left: 270px; /* Posisi horizontal di tengah */
    transform: translateX(-50%); /* Memastikan gambar benar-benar di tengah */
    z-index: 10; /* Membuat gambar berada di atas elemen lainnya */
    height: 30px !important; /* Tetap mengatur ukuran gambar */
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav li {
    margin: 5px 0; /* Mengurangi margin antar item */
    font-size: 8px !important; /* Mengurangi ukuran font */
    display: block;
  }

  nav ul li a {
    font-size: 10px !important;
    text-align: left !important;
  }

  .business-btn {
    font-size: 10px; /* Mengurangi ukuran font tombol */
    margin-top: 5px; /* Menambah margin atas */
  }

  nav .d-flex {
    flex-direction: column;
    align-items: center;
  }
  .navbar-toggler-icon {
    color: wheat;
    background-color: white;
    font-size: 10px !important;
  }
}

/* Bawah Navbar */
@media (max-width: 380px) {
  .head {
    margin-top: 80px;
    width: 320px !important;
    height: 580px !important;
    margin-left: -50px !important;
  }

  .head .row {
    flex-direction: column;
  }
  .head .col-8,
  .head .col-4 {
    width: 100%;
    text-align: center;
  }
  .pushing {
    font-size: 30px !important;
  }

  .head h3 {
    font-size: 15px !important;
  }

  .head h5 {
    font-size: 15px !important;
    margin-top: -130px !important;
  }

  .head h4 {
    font-size: 15px !important;
    margin: 20px !important;
  }

  .head .business-btn {
    font-size: 10px !important;
  }
  .pembungkusAseet {
    margin-left: 120px !important;
  }

  .head img {
    width: 150px;
    margin-top: 60px;
  }
}
/* Judul 1 */
@media (max-width: 380px) {
  #game {
    font-size: 15px;
    text-align: center;
  }

  .game-ini {
    font-size: 13px !important;
    font-weight: bold;
    margin-bottom: 50px !important;
  }
}
/* Vidio pertama */
@media (max-width: 380px) {
  .box-vid {
    display: flex;
    flex-direction: column; /* Elemen bertumpuk vertikal */
    width: 320px !important;
    margin-left: -50px !important;
    height: 550px !important; /* Paksa tinggi */
    overflow: hidden; /* Potong konten berlebih */
    background: radial-gradient(#08181f00, #08181f);
    border-radius: 35px;
    border: 1px solid #707172;
  }

  .sample {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Sesuaikan dengan lebar box */
    margin: 0 auto; /* Pusatkan elemen */
  }

  .sample-doc {
    width: 320px; /* Samakan lebar dengan box */
    height: auto; /* Tinggi otomatis menyesuaikan */
    max-width: 320px; /* Batasi lebar maksimum */
  }

  .penutup {
    width: 100%; /* Lebar penuh pada layar kecil */
    text-align: center; /* Pusatkan teks */
    padding: 10px; /* Tambahkan jarak untuk teks */
  }

  .penutup h4,
  .penutup h6 {
    font-size: 12px; /* Sesuaikan ukuran font */
    margin: 5px 0; /* Atur jarak antar teks */
  }
}

/* foto kedua */
@media (max-width: 380px) {
  .box {
    display: flex;
    flex-direction: column; /* Elemen bertumpuk vertikal */
    width: 320px !important;
    margin-left: -50px !important;
    height: 550px !important; /* Paksa tinggi */
    overflow: hidden; /* Potong konten berlebih */
    background: radial-gradient(#08181f00, #08181f);
    border-radius: 35px;
    border: 1px solid #707172;
  }
  .row {
    flex-direction: column;
  }

  .col-6 {
    width: 100%;
    padding: 10px;
  }

  .col-6 img {
    width: 300px !important;
    margin-top: -90px !important;
  }

  .about {
    font-size: 10px !important;
  }

  .Gaming {
    font-size: 10px !important;
  }
  .create {
    font-size: 10px !important;
    width: 300px !important;
  }
  .kotak-gambar {
    width: 250px !important;
    display: flex;
    flex-wrap: wrap; /* Membuat elemen turun ke bawah jika tidak muat */
    padding: 10px; /* Menambahkan padding agar konten tidak terlalu rapat */
  }

  .kotak-gambar > * {
    flex: 1 1 auto; /* Membuat elemen di dalamnya fleksibel, menyesuaikan ruang yang tersedia */
  }
  .px-5 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .our {
    text-align: center;
    font-size: 20px;
  }
  .for {
    font-size: 15px !important;
  }
}
/* Servissss */
@media (max-width: 380px) {
  .full {
    display: flex;
    flex-direction: column; /* Elemen bertumpuk vertikal */
    width: 100%;
    max-width: 320px; /* Batas maksimal lebar */
    margin: 0 auto; /* Posisikan di tengah */
    gap: 20px; /* Jarak antar elemen */
  }

  .kotak {
    width: 100%; /* Menyesuaikan dengan kontainer */
    max-width: 320px; /* Batas maksimal lebar */
    height: 300px; /* Tinggi fleksibel */
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0; /* Mencegah kotak menyusut */
  }

  .inti {
    width: 320px !important;
    margin-bottom: 30px;
  }

  .card-service {
    text-align: center;
    padding: 20px;
  }

  .card-service h5 {
    margin-top: -30px;
    font-size: 15px !important;
  }

  .card-service p {
    font-size: 10px !important;
  }

  .learn-more {
    font-size: 10px !important;
  }
  .bawah {
    font-size: 15px !important;
    text-align: center;
  }
}
/* Foto slider */
@media (max-width: 380px) {
  .boxtim {
    background: radial-gradient(#08181f00, #08181f);
    border-radius: 25px;
    border: 1px solid #707172;
  }
  .mySwiper {
    width: 100%; /* Menyesuaikan dengan lebar layar */
    padding: 10px; /* Tambahkan padding agar ada ruang */
  }

  .swiper-slide {
    flex: 0 0 100%; /* Satu slide per baris pada layar kecil */
    max-width: 100%; /* Slide memenuhi layar */
    text-align: center; /* Pusatkan konten di dalam slide */
  }

  .tim-img img {
    width: 100%; /* Gambar memenuhi kontainer */
    max-width: 100%; /* Hindari gambar terlalu besar */
    height: auto; /* Menjaga proporsi gambar */
    border-radius: 8px; /* Tambahkan border-radius agar lebih menarik */
  }

  h5 {
    font-size: 12px; /* Ukuran font yang lebih mudah dibaca */
    line-height: 1.4; /* Tambahkan spasi antar baris */
    margin-bottom: 5px; /* Tambahkan margin bawah */
  }

  p {
    font-size: 10px; /* Ukuran font yang lebih proporsional */
    line-height: 1.4;
    margin: 0; /* Hindari margin besar pada layar kecil */
  }
}

/* From */
@media (max-width: 380px) {
  .kotak-pen {
    height: 930px !important;
  }
  .ready {
    font-size: 15px !important;
  }
  .join {
    font-size: 15px !important;
  }
  .patner {
    font-size: 12px !important;
    max-width: 300px; /* Batas maksimal lebar */
    margin-left: 5px !important;
  }
  .form-icon {
    display: none;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px !important;
  }

  input {
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    max-width: 320px; /* Batas maksimal lebar */
  }
  .textarea {
    margin-top: 10px !important;
    margin-bottom: 10px;
    max-width: 320px; /* Batas maksimal lebar */
  }
  .send-message-btn {
    max-width: 320px; /* Batas maksimal lebar */
  }
}
/* foteeer */
@media only screen and (max-width: 380px) {
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .col-md-10,
  .col-md-2 {
    width: 100%;
    text-align: center;
  }

  .col-md-10 h5,
  .col-md-2 h5 {
    font-size: 10px !important;
  }

  .col-md-10 img.w-25 {
    width: 50%; /* Sesuaikan ukuran gambar */
  }

  .col-md-2 img.w-50 {
    width: 50%; /* Sesuaikan ukuran gambar */
  }

  .col-md-10 a img,
  .col-md-2 a img {
    width: 30px; /* Perkecil ukuran ikon media sosial */
    margin-bottom: 40px;
  }

  .col-md-2 h5.text-reguler {
    font-size: 10px !important; /* Perkecil ukuran teks alamat */
    margin-top: 10px;
  }

  .me-3 {
    margin-right: 10px; /* Sesuaikan margin kanan pada icon */
  }
}

.copyright {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

@media only screen and (max-width: 380px) {
  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 320px; /* Batas maksimal lebar */
  }

  .copyright h5 {
    font-size: 15px !important;
    margin-bottom: 10px;
  }

  ul.test {
    gap: 30px;
  }

  ul.test li.tes {
    font-size: 10px !important;
    margin-top: 20px !important;
  }

  .mx-5 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .garis-bawah {
    height: 5px !important;
  }
  .no {
    font-size: 15px !important;
  }
  .copy {
    font-size: 8px !important;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: #fff;
}

.iconic {
  width: 350px;
  margin-top: 60px;
}

.th-hero-wrapp {
  position: absolute;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

/* Styling untuk animasi cloud */
.hero11-shape {
  position: absolute;
  width: 3px;
  height: 60px;
  background: linear-gradient(to bottom, rgba(0, 123, 255, 0.7), rgba(0, 123, 255, 0));
  opacity: 0;
  animation: cloudAni 10s linear infinite;
}

/* Set masing-masing shape di posisi yang berbeda */
.hero11-shape.style1 {
  top: 10%;
  left: 20%;
  animation-delay: 0s;
}

.hero11-shape.style2 {
  top: 30%;
  right: 10%;
  animation-delay: 1s;
}

.hero11-shape.style3 {
  bottom: 15%;
  left: 10%;
  animation-delay: 2s;
}

.hero11-shape.style4 {
  bottom: 5%;
  right: 15%;
  animation-delay: 3s;
}

/* Set masing-masing shape di posisi yang berbeda */
.hero11-shape.style5 {
  top: 10%;
  left: 20%;
  animation-delay: 0s reverse;
}

.hero11-shape.style6 {
  top: 30%;
  right: 10%;
  animation-delay: 1s reverse;
}

.hero11-shape.style7 {
  bottom: 15%;
  left: 10%;
  animation-delay: 2s reverse;
}

.hero11-shape.style8 {
  bottom: 5%;
  right: 15%;
  animation-delay: 3s reverse;
}

/* Keyframes untuk animasi */
@keyframes cloudAni {
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-300px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.5;
  }
}

/*untuk media di ukuran 380 px */
</style>
